/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-04-10T15:45:08.783Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.2.10000-1785478';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
