import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from './locales/keys';

export const REGEXES = {
    EMAIL: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
    NUMBERS_ONLY: /^[0-9]*$/,
    // eslint-disable-next-line no-useless-escape
    NUMBERS_OR_SSN_ASTERISK: /^[\*]{5}\d{4}|[0-9]{9}$/,
    NUMBERS_ONLY_OR_BLANK: /^\d*$/,
    // eslint-disable-next-line no-useless-escape
    PHONE_NUMBER: /^[\+]?\d*$/ //This is to validate the phone number to accept an optional + followed by the digits(only)
};
export const DEBOUNCE_TIME_MS = 300;
export const DBSS_DECIMAL_PRECISION = 6;
export const MAX_SERVICE_FEATURE_ITEMS = 1;
export const NOTIFICATION_TIME_LENGTH = 8000;
export const RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION = 30000;

export const INVALID_SHOPPING_CART_FAULT_CODE = 1019;
export const INSUFFICIENT_CREDIT_AMOUNT_FAULT_CODE = 9082;

export const RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE = 11164;
export const QUOTE_BYPASSED_WARNING_CODE = 11197;

export const INVALID_INVENTORY_REASON_CODES = {
    NOT_VALID:1,
    NOT_FOUND:2,
    NOT_AVAILABLE:3
};

export const BILLER_RULE_QUANTITY_PRICE_TYPE = {
    NONE:1,
    TIERED:2,
    TAPERED:3
};

export const ORDER_SCENARIOS = {
    NEW_CONNECT: 1,
    CHANGE_ORDER: 2,
    ADD_OFFER: 3,
    TRANSFER_ORDER: 4,
    TRANSITION_OFFER: 5
};

export const SUBSCRIBER_TYPE_CODES = {
    RESIDENTIAL: 5
};

export const SUBSCRIBER_CATEGORY_CODES = {
    COMMERCIAL: 1,
    RESIDENTIAL: 0
};

export const INVOICE_TIMING_CONSTANTS = {
    INVOICE_ON_CYCLE: 1,
    INVOICE_IMMEDIATELY: 2
};

export const BILLER_RULE_INSTANCE_TYPE = {
    CUSTOM: 4,
    EARLY_TERMINATION_FEE: 5,
    ENTITLEMENT: 3,
    FINANCE: 8,
    ONETIME: 2,
    RECURRING: 0,
    SUBSCRIPTION: 6,
    TRIGGER: 7,
    USAGE: 1
};

export const SCHEDULED_ORDER_OPTIONS = {
    EFFECTIVE_IMMEDIATELY: 1,
    ON_NEXT_BILL_CYCLE: 2,
    ON_SPECIFIED_DATE: 3
};

export const BILLING_EFFECTIVE_DATE_ORDER_OPTIONS = {
    ON_ORDER_COMPLETION: 1,
    ON_LAST_ORDER: 2,
    ON_SPECIFIED_DATE: 3,
    ON_NEXT_BILL_CYCLE: 4
};

export const BILLER_RULE_INSTANCE_TYPE_COMBINATIONS = {
    STANDALONE_USAGE: 'standaloneUsage'
};

export const ZERO_DOWNPAYMENT = 0;
export const ZERO_TAX_AMOUNT = 0;
export const ONE_TIME_WASH = {
    NONE: 0,
    WASHED: 1,
    CHARGED: 2
};

export const DISCOUNT_TYPE = {
    PERCENTAGE_OFF: 1,
    AMOUNT_OFF: 3
};

export const SEARCH_INVENTORY_NO_RESULTS = '1610';

export const ROOT_CARE_MODULE_ROUTE = 'index.customercare';

export const DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE = 1068;

export const REASON_CODES = {
    BUYERS_REMORSE: '1',
    PORT_OUT: '2',
    RECONNECT: '4',
    STANDARD: '3',
    TREATMENT_DISCONNECT: '5'
};

export const ENTITLEMENT_CONTEXT_TYPES = {
    NO_SHARING: 1,
    ACCOUNT: 2,
    HIERARCHY: 3
};

export const ENTITLEMENT_SHARE_STATUS = {
    ENABLED: 1,
    DISABLED: 2
};

export const INVENTORY_CATEGORY_CODE = {
    NONE: 0,
    LOGICAL: 1,
    PHYSICAL: 2
};

export const SERVICE_ATTRIBUTE_TYPE = {
    ORDERING: 1,
    PROVISIONING: 2,
    INVARIANT: 3,
    ORDER_SUBMISSION: 4
};

export const INVENTORY_ITEM_RESERVATION_FAULT_CODE = 11026;

export const EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE = 11023;

export const MAX_INT_32 = 2147483647;

export const DEFAULT_SERVICE_IDENTIFIER_CODE_TABLE_VALUE = 1000000;

export const FINANCE_SERVICE_FEATURES_IDENTIFIER_VALUE = 999999;

export const PRODUCT_STATUS = {
    ACTIVE: 1,
    REMOVED: 2,
    PENDING_ACTIVE: 3,
    PENDING_REMOVED: 4,
    EXPIRED: 5,
    SUSPENDED:6
};

export const ORDER_TYPE_CODE = {
    NEW: '1',
    REMOVE: '3',
    SUSPEND: '4',
    RESTORE: '5',
    CHANGE_OF_SERVICE: '8'
};

export const ORDER_CHANGE_TYPE_CODE = {
    BUYERS_REMORSE: '1',
    DISCONNECT: '6',
    OFF_CYCLE_RENEWAL: '7',
    PORT_OUT: '2',
    RECONNECT: '4',
    TREATMENT_DISCONNECT: '5',
    VOLUNTARY_SUSPENSION: '12',
    NON_PAY_SUSPENSION: '13'
};

export const REASON_ADDITIONAL_PROPERTY_KEYS = {
    ORDER_TYPE_CODE: 'order_type_code',
    ORDER_CHANGE_TYPE_CODE: 'order_change_type_code'
};

export const UNIT_OF_MEASURES = {
    CURRENCY: '8',
    NOT_APPLICABLE: '0',
    UNITS: '3'
};

export const USAGE_CALL_DIRECTION = {
    CALL_FORWARDING: 'CF',
    INCOMING: 'MT',
    OUTGOING: 'MO'
};

export const ITEM_QUOTE_STATUS = {
    ADDED: 1,
    REMOVED: 2
};

export const BYTE_UNIT_CODE = 4;
export const MINUTE_UNIT_CODE = 2;
export const SECOND_UNIT_CODE = 1;

export const ENTITLEMENT_PURCHASE_TYPE = {
    PURCHASE_ONLY: 1,
    PURCHASE_AND_USAGE: 2,
    USAGE_ONLY: 3
};

export const DEVICE_ADDON_SERVICE_ATTRIBUTE_ID = 999999;

export const SERVICE_ENTITLEMENTS_ACTIVATION_STATUS = {
    ENTITLEMENT_INACTIVE: 0,
    ENTITLEMENT_ACTIVE: 1,
    AWAITING_ENTITLEMENT_FIRST_USE: 2,
    AWAITING_SERVICE_FIRST_USE: 3,
    AWAITING_PAYMENT_CONFIRMATION: 4,
    AWAITING_ENTITLEMENT_FIRST_USE_WITH_PRICING_PLAN: 5
};

export const ORDER_ITEM_DISCOUNT_ACTIONS = {
    ADDED: 1,
    REMOVED: 2,
    UNCHANGED: 3
};

export const TRANSACTION_TYPE = {
    ALA_CARTE: 7,
    BATCH_PAYMENT: 5,
    BILL_PAY: 8,
    ORDER_PROCESSING: 2,
    PAYMENT_INSTRUMENT_DEPOSIT: 6,
    PAYMENT_INTRUMENT_CHARGE: 3,
    REFUND: 9,
    SUBSCRIPTION_FEES: 4,
    PAYMENT_INSTRUMENT_VALIDATION: 1,
    RENEWAL: 10,
    EXTERNAL_DEPOSIT: 11,
    USAGE_PAYMENT: 12
};

export const TRANSACTION_STATUS = {
    OPEN: 1,
    COMPLETE: 2,
    SCHEDULE: 3
};

export const TRANSACTION_ITEM_TYPE = {
    NO_PROCESSING: 11,
    AUTHORIZATION_REVERSAL: 10,
    CAPTURE: 2,
    CREDIT:4,
    DEPOSIT: 5,
    SALE: 3,
    VOIDED_CREDIT: 7,
    VOIDED_DEPOSIT: 9,
    VOIDED_SALE: 6,
    WRITE_OFF: 8,
    WRITE_OFF_REVERSAL: 12,
    AUTHORIZATION: 1
};

export const USAGE_DETAILS_ACTION_TYPES = () => {
    return {
        AGGREGATE: i18n.translate(CustomerCareKeys.USAGE_DETAILS.ACTION_TYPE.AGGREGATED ),
        ONLINE_RATE: i18n.translate(CustomerCareKeys.USAGE_DETAILS.ACTION_TYPE.ONLINE_RATED ),
        RATE: i18n.translate(CustomerCareKeys.USAGE_DETAILS.ACTION_TYPE.RATED ),
        REPROCESS_EVENTS: i18n.translate(CustomerCareKeys.USAGE_DETAILS.ACTION_TYPE.REPROCESSED ),
        REVOKE: i18n.translate(CustomerCareKeys.USAGE_DETAILS.ACTION_TYPE.REVOKED )
    };
};

export const BILLER_RULE_CYCLE_LEVEL = {
    ITEM: 1,
    INVOICE: 2
};

export const PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE = {
    NONE: 0,
    PROVIDES: 1,
    CONSUMES: 2
};

export const OFFERING_METADATA_OFFER_TYPE = {
    STANDARD: 0,
    DEVICE: 1
};

export const DELIVERY_OPTIONS = {
    NONE: 0,
    STORE_PICKUP: 1,
    SHIPPED: 2
};

export const DOWN_PAYMENT_TYPE = {
    DOWN_PAYMENT_WITH_INSTALLMENT: 1,
    FULL_UPFRONT_PAYMENT: 2,
    DOWN_PAYMENT_EQUAL_TO_FIRST_INSTALLMENT: 3
};

export const OFFERING_ACTION_TYPE = {
    NONE: 0,
    MODIFY: 1,
    REMOVE: 2,
    TRANSITION: 3,
    RECONNECT: 4,
    TRANSFER: 5,
    GROUP_SUSPEND: 6,
    GROUP_RESUME: 7,
    OPT_OUT: 8,
    OPT_IN: 9
};

export const ACCOUNTING_METHOD_TYPES = {
    BALANCE_FORWARD: 1,
    OPEN_INVOICE: 2
};

export const INVOICE_DETAIL_LEVEL = {
    SUMMARY: '1',
    DETAIL: '2'
};

export const TAX_RULE_OPTIONS = {
    DEFAULT: 1,
    CUSTOM: 2
};

export const TAX_LOCATION_CUSTOM_OPTIONS_HASH = {
    0: 'default',
    1: 'AddressId',
    2: 'NpaNxx'
};

export const TAX_LOCATION_CUSTOM_OPTIONS = {
    ADDRESS_ON_FILE: 1,
    DEFAULT: 0,
    NPANXX: 2
};

export const TAX_SERVICE_LOCATION_REFERENCES = {
    BILL_TO_LOCATION: 'BillToLocation',
    ORIGINATING_TAX_SERVICE_LOCATION: 'OriginatingTaxServiceLocation',
    TERMINATING_TAX_SERVICE_LOCATION: 'TerminatingTaxServiceLocation'
};

export const RATE_SHEET_ITEM_TYPES = {
    ONE_TIME: 1,
    RECURRING: 2,
    ENTITLEMENT: 3,
    USAGE_RULE_NON_TIERED: 4,
    USAGE_RULE_PER_EVENT: 5,
    USAGE_RULE_TIERED: 6,
    FLAT_USAGE_RATES: 7,
    ROUNDING_RULES: 8
};

export const TIME_BANDS = {
    ANYTIME: '0',
    PEAK: '1',
    OFF_PEAK: '2',
    HOLIDAY: '3',
    WEEKEND: '4'
};

export const RATE_SHEET_TABS = {
    PRODUCT_RATES: '1',
    PER_EVENT_USAGE_RATES: '2',
    TIERED_USAGE_RATES: '3',
    FEES: '4',
    ROUNDING_RULES: '5'
};

export const RATE_SHEET_TAB_IDS = {
    PRODUCT_RATES: 0,
    FLAT_EVENT_USAGE_RATES: 1
};

export const UOM_CATEGORIES = {
    DATA_IT: 'Data - IT',
    DATA_SCIENTIFIC: 'Data - Scientific',
    DATA_TELCO: 'Data - Telco',
    DATA_UNIVERSAL: 'Data - Universal'
};

export const USAGE_DETAILS_TABS_IDS = {
    PRODUCT_RATES: '1',
    FLAT_PER_EVENT_USAGE_RULES: '2',
    TIERED_USAGE_RULES: '3'
};

export const USAGE_CHARGE_TYPES = {
    DEFAULT: '4',
    ZERO_RATE: '1',
    PER_EVENT: '2',
    FLAT_RATE: '3'
};

export const USAGE_BAND_TYPES = {
    0: 'anyTime',
    1: 'peak',
    2: 'offPeak',
    3: 'holidays',
    4: 'weekends'
};

export const USAGE_BAND_FORMATTED_TYPES = {
    0: 'anyTimeFormatted',
    1: 'peakFormatted',
    2: 'offPeakFormatted',
    3: 'holidayFormatted',
    4: 'weekendFormatted'
};

export const USAGE_CHARGE_TYPE_TEXTS = {
    1: 'Zero Rate',
    2: 'Per Event',
    3: 'Flat Rate'
};

export const USAGE_CHARGE_TO_RATE_SHEET_ITEM_TYPE = {
    2: RATE_SHEET_ITEM_TYPES.USAGE_RULE_PER_EVENT,
    3: RATE_SHEET_ITEM_TYPES.FLAT_USAGE_RATES,
    4: RATE_SHEET_ITEM_TYPES.USAGE_RULE_NON_TIERED
};

export const TEXT_INPUT_FIELD_NUM_OF_COLUMNS = 5;

export const SERVICE_ATTRIBUTE_DISPLAY_TYPES = {
    HIDDEN: '1',
    VIEWABLE: '2',
    EDITABLE: '3'
};

export const AUTO_RENEW_ENTITY_TYPES = {
    OFFER: 'Offer',
    PRICING_PLAN: 'PricingPlan'
};

export const AUTO_RENEW_ACTION_TYPES = {
    ENABLE: 'Enable',
    DISABLE: 'Disable'
};

export const BILL_CYCLE_TYPES = {
    STANDARD: 1,
    THREE_STEP: 2
};

export const HIERARCHY_MOVE_STATUS_TYPES = {
    WithinHierarchy: 1,
    AcrossHierarchies: 2
};
