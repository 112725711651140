import path from 'ramda/src/path';
import clone from 'ramda/src/clone';
import pluck from 'ramda/src/pluck';
import pathOr from 'ramda/src/pathOr';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import {
    hasAccess,
    getUpperLimitAccess
} from 'invision-core/src/components/security/permission.service';

import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';

import {
    NOTIFICATION_TIME_LENGTH,
    TRANSACTION_ITEM_TYPE,
    TRANSACTION_STATUS,
    TRANSACTION_TYPE
} from '../../../../customercare.constants';
import {BLANK_PAYMENT_INSTRUMENT} from '../../makePayment/make.payment.constants';
import {DASHBOARD_ROUTE} from '../../../../reducers/constants/dashboard.constants';

import {
    ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT,
    ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT,
    ISSUE_WRITE_OFF
} from '../../../../security.attributes';
import CustomerLocaleKeys from '../../../../locales/keys';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerIdSelector,
    RoutePreviousState,
    IsPIIDataAccessEnabledSelector,
    RouteParams
} from './../../../../reducers/selectors/customer.selectors';
import {PaymentInstrumentsSelector} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {
    CanRetryTransactionSelector,
    CurrentTransactionDetailsPurchaseOrderItemsSelector,
    CurrentTransactionDetailsSelector,
    CurrentTransactionIdSelector,
    IsEligibleForPartialRefundsSelector,
    IsFetchingDataSelector,
    MutableTransactionDetailPaymentInstruments,
    PrintTransactionDetailsSelector
} from '../../../../reducers/selectors/customer.transactions.selectors';
import {
    emailTransaction,
    retrieveTransaction
} from '../../../../reducers/actions/customer.transactions.actions';
import {retrieveConvergentBillerSubscriberSummary} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {transactionTypeNameIdentifier} from '../transactions.helpers';
import {
    LIST_STATE_OR_NAME,
    TRANSACTION_DETAILS_STATE
} from '../transactions.config';
import {transactionDetails} from '../../../../print';
import {
    CurrentBusinessUnitCurrencyCodeSelector,
    IsDbss
} from 'invision-core/src/components/session/businessunit.selectors';
import {CanApplyCreditSelector, CanChangePaymentInstrument, MutablePaymentInstrumentsTypeFilterOptionsSelector} from '../../../../reducers/selectors/apply.credit.modal.selectors';
import {
    renewOffCycleCharges,
    retrieveAvailablePaymentInstrumentTypes,
    retrieveWallet,
    updateOfferingInstancePaymentInstrument
} from '../../../../reducers/actions/customer.ewallet.actions';
import {
    ConvergentBillerSubscriberOfferingSummariesSelector,
    CurrentAccountSummarySelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';

import {SERVICES_AND_SHARED_ENTITLEMENTS, SERVICES_USAGE_STATE_NAME} from '../../servicesAndUsage/services.and.usage.config';
import {MENU_ITEMS} from '../../dashboard/dbssDashboard/dashboard.constants';
import {SessionSelectors} from 'invision-core';
import {SERVICE_USAGE_DETAILS_STATE_NAME} from '../../servicesAndUsage/usageDetails/usage.details.config';

const MORE_OPTIONS_IDENTIFIER = {
    WRITE_OFF: 0,
    PRINT: 1,
    EMAIL: 2
};

const orderDetailsRoute = 'index.customercare.customer.orderHistory.details';

class DetailController {
    constructor($window, $ngRedux, $timeout, uiNotificationService, $state) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            $window,
            backButtonText: '',
            closeChangePaymentMethodPopup: this.closeChangePaymentMethodPopup.bind(this),
            closeCreditRefundModal: this.closeCreditRefundModal.bind(this),
            CustomerLocaleKeys,
            isModifyPaymentMethodPopupShown: false,
            onBannerClose: this.onBannerClose.bind(this),
            onChangePaymentMethod: this.onChangePaymentMethod.bind(this),
            onCloseModifyPaymentMethodPopup: this.onCloseModifyPaymentMethodPopup.bind(this),
            openStatementDetails: this.openStatementDetails.bind(this),
            onSuccessModifyPaymentMethodPopup: this.onSuccessModifyPaymentMethodPopup.bind(this),
            shouldShowBackBanner: false,
            showCreditRefundModal: false,
            transactionTypeNameIdentifier,
            TRANSACTION_TYPE,
            uiNotificationService
        });
    }
    $onInit() {
        this.transactionItemColumns = [
            {
                field: 'TransactedDate',
                cellTemplate: require('./cellTemplates/transaction/date.html'),
                displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTED_DATE),
                minWidth: 70,
                manWidth: 100,
                enableColumnResizing: true,
            },
            {
                field: 'TypeName',
                displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TYPE),
                cellTemplate: require('./cellTemplates/transaction/type.html'),
                enableColumnResizing: true,
                minWidth: 120
            },
            {
                field: 'TransactionTypeName',
                displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.ITEM_TYPE),
                cellTemplate: require('./cellTemplates/transaction/item.type.html'),
                enableColumnResizing: true,
                minWidth: 120
            },
            {
                field: 'ResultCode',
                displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.RESULT),
                cellTemplate: require('./cellTemplates/transaction/result.html'),
                minWidth: 70,
                enableColumnResizing: true,
            },
            {
                field: 'Amount',
                displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.AMOUNT),
                cellTemplate: require('./cellTemplates/transaction/amount.html'),
                enableColumnResizing: true,
                headerCellClass: 'u-pr++',
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                minWidth: 100
            }
        ];
        this.purchaseOrderColumnDefs = [{
            field: 'date',
            cellTemplate: require('./cellTemplates/purchaseOrder/date.html'),
            displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.DATE),
            minWidth: 70,
            enableColumnResizing: true,
            width: 150
        }, {
            field: 'type',
            displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.ITEM_TYPE),
            enableColumnResizing: true,
            minWidth: 100
        }, {
            field: 'description',
            displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.DESCRIPTION),
            enableColumnResizing: true,
            minWidth: 350,
            footerCellTemplate: require('./cellTemplates/purchaseOrder/subtotal.html')
        }, {
            field: 'attempted',
            cellTemplate: require('./cellTemplates/purchaseOrder/attempted.html'),
            displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.ATTEMPTED_AMOUNT),
            enableColumnResizing: true,
            headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
            width: 180,
            footerCellTemplate: require('./cellTemplates/purchaseOrder/total.attempted.html')
        }, {
            field: 'transacted',
            cellTemplate: require('./cellTemplates/purchaseOrder/transacted.html'),
            displayName: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTED_AMOUNT),
            enableColumnResizing: true,
            headerCellClass: 'u-pr++',
            headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
            width: 180,
            footerCellTemplate: require('./cellTemplates/purchaseOrder/total.transacted.html')
        }];

        const mapStateToTarget = (store) => {
            return {
                adjustmentReasonOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.BalanceAdjustmentReason, store),
                adjustmentReasonsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.BalanceAdjustmentReason, store),
                canApplyCredit: CanApplyCreditSelector(store),
                canChangePaymentInstrument: CanChangePaymentInstrument(store),
                canRetryTransaction: CanRetryTransactionSelector(store),
                convergentBillerSubscriberOfferingSummaries: ConvergentBillerSubscriberOfferingSummariesSelector(store),
                creditReasonsLoaded: MetadataSelectors.codes.CreditReasonsLoadedSelector(store),
                currentAccountSummary: CurrentAccountSummarySelector(store),
                currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomerCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentTransactionDetails: CurrentTransactionDetailsSelector(store),
                currentTransactionId: CurrentTransactionIdSelector(store),
                ewalletPaymentInstruments: PaymentInstrumentsSelector(store),
                featureTogglesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.FeatureToggleConfig, store),
                isDbss: IsDbss(store),
                isEligibleForPartialRefunds: IsEligibleForPartialRefundsSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
                isPosPaymentTypeLoaded:  MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PointOfSalePaymentType, store),
                lastRoute: SessionSelectors.LastRouteSelector(store),
                mutableTransactionDetailPaymentInstruments: MutableTransactionDetailPaymentInstruments(store),
                paymentInstrumentsTypeFilterOptions: MutablePaymentInstrumentsTypeFilterOptionsSelector(store),
                paypalPaymentTypeLoaded:  MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PaypalPaymentType, store),
                paypalPaymentTypes:  MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PaypalPaymentType, store),
                posPaymentTypes:  MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PointOfSalePaymentType, store),
                printTransactionDetails: PrintTransactionDetailsSelector(store),
                purchaseOrderItems: CurrentTransactionDetailsPurchaseOrderItemsSelector(store),
                reasonOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.CreditReasons, store),
                routeParams: RouteParams(store),
                routePreviousState: RoutePreviousState(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            emailTransaction,
            fetchAdjustmentReasons: MetadataActions.codes.fetchAdjustmentReasons,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            fetchCreditReasons: MetadataActions.codes.fetchCreditReasons,
            renewOffCycleCharges,
            retrieveAvailablePaymentInstrumentTypes,
            retrieveConvergentBillerSubscriberSummary,
            retrieveTransaction,
            retrieveWallet,
            stateGo,
            updateOfferingInstancePaymentInstrument
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = this.state.currentRoute;
        this.dashboardRoute = DASHBOARD_ROUTE;
        this.DEFAULT_BACK_ROUTE = LIST_STATE_OR_NAME;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        if (!this.state.currentTransactionDetails) {
            this.actions.retrieveTransaction(this.state.currentTransactionId, this.state.currentCustomerId, this.state.isDbss).then(() => {
                this.isGracePeriodPaymentFailure = this.state.canRetryTransaction;
            });
        }

        if (!this.state.paypalPaymentTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaypalPaymentType);
        }

        if (!this.state.isPosPaymentTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PointOfSalePaymentType);
        }

        if (!this.state.featureTogglesLoaded) {
            this.actions.fetchCodeType(CODES.FeatureToggleConfig);
        }

        if (!this.state.creditReasonsLoaded) {
            this.actions.fetchCreditReasons();
        }

        if (!this.state.adjustmentReasonsLoaded) {
            this.actions.fetchAdjustmentReasons();
        }

        this.transactionTypePopupConfig = {
            onRegisterApi: ({api}) => {
                this.transactionTypePopupApi = api;
            }
        };

        this.changePaymentPopupConfig = {
            onRegisterApi: ({api}) => {
                this.changePaymentPopupApi = api;
            }
        };

        this.modifyPaymentMethodPopupConfig = {
            onRegisterApi: ({api}) => {
                this.modifyPaymentMethodPopupApi = api;
            }
        };

        this.writeOffPopupConfig = {
            onRegisterApi: ({api}) => {
                this.writeOffPopupApi = api;
            }
        };

        this.applyCreditModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyCreditModalApi = api;
            }
        };

        this.applyCreditRefundModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyCreditRefundModalApi = api;
            }
        };

        this.emailPopupConfig = {
            onRegisterApi: ({api}) => {
                this.emailPopupApi = api;
            }
        };

        this.moreOptionsArray = [{
            title: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.PRINT_TRANSACTION),
            id: MORE_OPTIONS_IDENTIFIER.PRINT
        }];

        if (this.state.routePreviousState.name.match(orderDetailsRoute) !== null) {
            this.shouldShowBackBanner =  true;
            this.backButtonText = i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.RETURN_TO_ORDER_DETAILS);
        } else if (this.state.routePreviousState.name.match(SERVICES_AND_SHARED_ENTITLEMENTS) !== null) {
            this.shouldShowBackBanner =  true;
            this.backButtonText = i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.RETURN_TO_SHARED_ENTITLEMENTS);
        } else if (this.state.routeParams && this.state.routeParams.orderId) {
            // This logic is required when coming from react order details page to show back banner
            this.shouldShowBackBanner =  true;
            this.backButtonText = i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.RETURN_TO_ORDER_DETAILS);
            this.previousRoute= {
                name: orderDetailsRoute,
                params: {
                    orderId: this.state.routeParams.orderId
                }
            };
        }

        this.actions.retrieveAvailablePaymentInstrumentTypes({
            Currency: this.state.currentCustomerCurrencyCode || this.state.currentBusinessUnitCurrencyCode
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        if (this.state.isDbss) {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getTransactionTypeName() {
        if (this.state.currentTransactionDetails) {
            switch (this.state.currentTransactionDetails.Type) {
                case TRANSACTION_TYPE.ALA_CARTE:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.ALA_CARTE);
                case TRANSACTION_TYPE.BATCH_PAYMENT:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.BATCH_PAYMENT);
                case TRANSACTION_TYPE.BILL_PAY:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.BILL_PAY);
                case TRANSACTION_TYPE.ORDER_PROCESSING:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.ORDER_PROCESSING);
                case TRANSACTION_TYPE.PAYMENT_INSTRUMENT_DEPOSIT:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.PAYMENT_INSTRUMENT_DEPOSIT);
                case TRANSACTION_TYPE.PAYMENT_INSTRUMENT_CHARGE:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.PAYMENT_INSTRUMENT_CHARGE);
                case TRANSACTION_TYPE.PAYMENT_INSTRUMENT_VALIDATION:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.PAYMENT_INSTRUMENT_VALIDATION);
                case TRANSACTION_TYPE.REFUND:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.REFUND);
                case TRANSACTION_TYPE.RENEWAL:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.RENEWAL);
                case TRANSACTION_TYPE.SUBSCRIPTION_FEES:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.SUBSCRIPTION_FEE);
                case TRANSACTION_TYPE.EXTERNAL_DEPOSIT:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.EXTERNAL_DEPOSIT);
                case TRANSACTION_TYPE.USAGE_PAYMENT:
                    return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.USAGE_PAYMENT);
                default:
                    break;
            }
        }
    }

    openStatementDetails() {
        this.actions.stateGo(SERVICE_USAGE_DETAILS_STATE_NAME, {
            customerId: this.state.currentCustomerId,
            statementId: this.state.currentTransactionDetails.ExternalReference,
            serviceIdentifierValue: this.state.currentTransactionDetails.PurchaseOrder?.Items?.[0]?.PurchaseInformation?.ServiceIdentifier?.Value,
            serviceAttributeId: this.state.currentTransactionDetails.PurchaseOrder?.Items?.[0]?.PurchaseInformation?.ServiceIdentifier?.ServiceAttributeId,
            tab: 'usage',
            searchBy: 'UsageStatementId',
            allEntitlements: true
        });
    }

    onMoreItemSelected(item) {
        switch (item.id) {
            case MENU_ITEMS.ADD_PAYMENT_INSTRUMENT:
                this.openPaymentMethodPopup(false);
                break;
            case MENU_ITEMS.EDIT_PAYMENT_INSTRUMENT:
                this.openPaymentMethodPopup(true);
                break;
            case MENU_ITEMS.CHANGE_PAYMENT_INSTRUMENT:
                this.openChangePaymentMethodPopup();
                break;
            default:
                break;
        }
    }

    getItemTypeName(item) {
        switch (item.TransactionType) {
            case TRANSACTION_ITEM_TYPE.AUTHORIZATION_REVERSAL:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.AUTHORIZATION_REVERSAL);
            case TRANSACTION_ITEM_TYPE.CAPTURE:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.CAPTURE);
            case TRANSACTION_ITEM_TYPE.CREDIT:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.CREDIT);
            case TRANSACTION_ITEM_TYPE.DEPOSIT:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.DEPOSIT);
            case TRANSACTION_ITEM_TYPE.SALE:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.SALE);
            case TRANSACTION_ITEM_TYPE.VOIDED_CREDIT:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.VOIDED_CREDIT);
            case TRANSACTION_ITEM_TYPE.VOIDED_DEPOSIT:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.VOIDED_DEPOSIT);
            case TRANSACTION_ITEM_TYPE.VOIDED_SALE:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.VOIDED_SALE);
            case TRANSACTION_ITEM_TYPE.WRITE_OFF:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.WRITE_OFF);
            case TRANSACTION_ITEM_TYPE.WRITE_OFF_REVERSAL:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.WRITE_OFF_REVERSAL);
            case TRANSACTION_ITEM_TYPE.AUTHORIZATION:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.AUTHORIZATION);
            case TRANSACTION_ITEM_TYPE.NO_PROCESSING:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.TRANSACTION_ITEM_TYPE.NO_PROCESSING);
            default:
                break;
        }
    }

    getTransactionStatus(status) {
        switch (status) {
            case TRANSACTION_STATUS.OPEN:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.OPEN);
            case TRANSACTION_STATUS.COMPLETE:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.COMPLETE);
            case TRANSACTION_STATUS.SCHEDULE:
                return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.SCHEDULE);
            default:
                break;
        }
    }

    handleOptionSelected(item) {
        switch (item.id) {
            case MORE_OPTIONS_IDENTIFIER.WRITE_OFF:
                this.openWriteOffPopup();
                break;
            case MORE_OPTIONS_IDENTIFIER.PRINT:
                this.openPrinterFriendly();
                break;
            case MORE_OPTIONS_IDENTIFIER.APPLY_CREDIT:
                this.openTransactionLevelCreditModal();
                break;
            case MORE_OPTIONS_IDENTIFIER.EMAIL:
                this.openEmailDialog();
                break;
            case MENU_ITEMS.RETRY_PAYMENT: {
                const lockerItemIds = pluck('LockerItemId', this.state.currentTransactionDetails.PurchaseOrder.Items);
                this.actions.renewOffCycleCharges(this.state.currentCustomerId, lockerItemIds)
                    .then(() => {
                        this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.MAKE_PAYMENT.PAYMENT_SUCCESSFUL));
                    })
                    .catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                break;
            }
            default:
                break;
        }
    }

    moreOptions() {
        // If the Array is constructed here, a digest cycle infinite loop is created because the reference
        // change causes a digest to trigger. We dynamically build the array to ensure state changes are
        // reflected accurately in the options.
        if (this.state.isPIIDataAccessEnabled && !this.hasInsertedEmailTransaction) {
            this.moreOptionsArray.push({
                title: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.EMAIL_TRANSACTION),
                id: MORE_OPTIONS_IDENTIFIER.EMAIL
            });
            this.hasInsertedEmailTransaction = true;
        }

        if (this.canIssueWriteOff && !this.hasInsertedWriteOff) {
            this.moreOptionsArray.push({
                title: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.WRITE_OFF),
                id: MORE_OPTIONS_IDENTIFIER.WRITE_OFF
            });
            this.hasInsertedWriteOff = true;
        }

        if (this.canApplyCredit && !this.hasInsertedApplyCredit && !this.isLockbox) {
            this.moreOptionsArray.push({
                title: i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.APPLY_CREDIT),
                id: MORE_OPTIONS_IDENTIFIER.APPLY_CREDIT
            });
            this.hasInsertedApplyCredit = true;
        }

        if (this.isGracePeriodPaymentFailure) {
            this.moreOptionsArray.push({
                title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.RETRY_PAYMENT),
                id: MENU_ITEMS.RETRY_PAYMENT
            }, {
                title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.MANAGE_PAYMENT_INSTRUMENT),
                subitems: [{
                    id: MENU_ITEMS.EDIT_PAYMENT_INSTRUMENT,
                    title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.EDIT_EXISTING)
                }, {
                    id: MENU_ITEMS.CHANGE_PAYMENT_INSTRUMENT,
                    title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.CHANGE)
                }, {
                    id: MENU_ITEMS.ADD_PAYMENT_INSTRUMENT,
                    title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.ADD_NEW)
                }]
            });
            this.isGracePeriodPaymentFailure = false;
        }

        return this.moreOptionsArray;
    }

    get canIssueWriteOff() {
        return hasAccess(this.state.userSecurityAttributes, ISSUE_WRITE_OFF)
            && path(['state', 'currentTransactionDetails', 'PurchaseOrder', 'Totals', 'TotalWriteOffableAmount'])(this);
    }

    get canApplyCredit() {
        return this.state.canApplyCredit && this.csrCanIssueCredit
            && path(['state', 'currentTransactionDetails', 'PurchaseOrder', 'Totals', 'TotalCreditableAmount'])(this);
    }

    get csrCanIssueCredit() {
        return getUpperLimitAccess(this.state.userSecurityAttributes, ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT.id);
    }

    get isLockbox() {
        const lockboxPaymentData = pathOr(null, ['Items', 0, 'LockboxPaymentTransaction'], this.state.currentTransactionDetails);
        return !!lockboxPaymentData && !!lockboxPaymentData.RequestDate && !!lockboxPaymentData.ReceivedDate;
    }

    get canChangePaymentInstrument() {
        return hasAccess(this.state.userSecurityAttributes, ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT);
    }

    goBack() {
        const route = this.state.lastRoute.name || this.DEFAULT_BACK_ROUTE;
        const params = this.state.lastRoute.params || {};
        this.$state.go(route, params);
    }

    openWriteOffPopup() {
        this.showWriteOffPopup = true;
        this.$timeout(this.writeOffPopupApi.open);
    }

    closeWriteOffPopup() {
        this.showWriteOffPopup = false;
        this.writeOffPopupApi.close();
    }

    onSuccessfulWriteOff() {
        this.closeWriteOffPopup();
        this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.SUCCESSFUL_WRITE_OFF), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.actions.stateGo(TRANSACTION_DETAILS_STATE, {
            transactionId: this.state.currentTransactionId
        }, {
            reload: true
        });
    }

    closeTransactionTypePopup() {
        this.showTransactionTypePopup = false;
        this.transactionTypePopupApi.close();
    }

    onCloseModifyPaymentMethodPopup() {
        this.isModifyPaymentMethodPopupShown = false;
        this.modifyPaymentMethodPopupApi.close();
    }

    closeChangePaymentMethodPopup() {
        this.changePaymentPopupApi.close();
        this.showChangePaymentPopup = false;
    }

    onSuccessModifyPaymentMethodPopup() {
        this.actions.retrieveTransaction(this.state.currentTransactionId, this.state.currentCustomerId, this.state.isDbss)
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.onCloseModifyPaymentMethodPopup();
            });
    }

    onChangePaymentMethod(paymentInstrument) {
        const paymentInstrumentId = paymentInstrument.Default ? null : paymentInstrument.Id;
        const offeringInstanceId = pathOr(null, ['PurchaseOrder', 'Items', 0, 'PurchaseInformation', 'OfferingInstanceId'], this.state.currentTransactionDetails);
        this.actions.updateOfferingInstancePaymentInstrument(this.state.currentCustomerId, offeringInstanceId, paymentInstrumentId).then(() => {
            this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.MAKE_PAYMENT.EDIT_INSTRUMENT_SUCCESS));
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId, true);
            this.actions.retrieveTransaction(this.state.currentTransactionId, this.state.currentCustomerId, this.state.isDbss);
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        }).finally(() => {
            this.closeChangePaymentMethodPopup();
        });
    }

    openTransactionTypePopup(selectedTransaction) {
        this.selectedTransaction = selectedTransaction;
        this.selectedTransactionPaymentInstrument = this.state.currentTransactionDetails.paymentAmounts && this.state.currentTransactionDetails.paymentAmounts.find(({PaymentInstrument: {Id}}) => {
            return Id === selectedTransaction.PaymentInstrumentId;
        }).PaymentInstrument;
        this.showTransactionTypePopup = true;
        this.$timeout(this.transactionTypePopupApi.open);
    }

    openPrinterFriendly() {
        const invoiceWindow = this.$window.open('', '_blank');
        if (invoiceWindow) {
            invoiceWindow.document.write(transactionDetails(this.state.printTransactionDetails));
        } else {
            this.uiNotificationService.error(i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.UNABLE_TO_OPEN_WINDOW), {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        }
    }

    openCreditModal() {
        this.showCreditModal = true;
        this.$timeout(this.applyCreditModalApi.open);
    }

    lockBoxApiData() {
        return {
            AccountNumber:  pathOr( null, ['currentAccountSummary', 'AccountNumber'], this.state)
        };
    }

    creditModalConfig(apiData) {
        this.showCreditRefundModal = true;
        this.additionalApiData = this.isLockbox
            ? this.lockBoxApiData()
            : apiData || {
                PurchaseOrderId: this.state.currentTransactionDetails?.PurchaseOrder?.Id
            };
    }

    openCreditRefundModal(apiData = null) {
        if (this.canChangePaymentInstrument) {
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            }).then(() => {
                this.creditModalConfig(apiData);
            });
        } else {
            this.creditModalConfig(apiData);
        }
    }

    openPurchaseOrderItemLevelCreditModal(item) {
        const additionalApiData = {
            PurchaseOrderId: this.state.currentTransactionDetails?.PurchaseOrder?.Id,
            PurchaseOrderItemId: item.id
        };
        this.maxCreditAmount = item.creditableAmount;
        this.currencyCode = this.state.currentTransactionDetails?.PurchaseOrder?.Currency;
        this.openCreditRefundModal(additionalApiData);
    }

    openTransactionLevelCreditModal() {
        this.additionalApiData = {
            PurchaseOrderId: this.state.currentTransactionDetails?.PurchaseOrder?.Id
        };
        this.maxCreditAmount = this.state.currentTransactionDetails?.PurchaseOrder?.Totals?.TotalCreditableAmount;
        this.currencyCode = this.state.currentTransactionDetails?.PurchaseOrder?.Currency;
        this.openCreditRefundModal();
    }

    closeCreditModal() {
        this.showCreditModal = false;
        this.applyCreditModalApi.close();
    }

    closeCreditRefundModal() {
        this.showCreditRefundModal = false;
    }

    onCreditSubmission() {
        this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.SUCCESSFUL_CREDIT), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.actions.stateGo(TRANSACTION_DETAILS_STATE, {
            transactionId: this.state.currentTransactionId
        }, {
            reload: true
        });
        this.closeCreditModal();
    }

    openEmailDialog() {
        this.showEmailDialog = true;
        this.$timeout(this.emailPopupApi.open);
    }

    closeEmailDialog() {
        this.showEmailDialog = false;
        this.emailPopupApi.close();
    }

    getSubcriberPaymentInstrument() {
        const revenuePaymentInstrumentId = pathOr(null, ['PaymentAmounts', 0, 'PaymentInstrument'], this.state.currentTransactionDetails);
        const subcriberPaymentInstrumentIdObj = this.state.currentTransactionDetails.Items.find((item) => {
            return item.PaymentInstrumentId === revenuePaymentInstrumentId.Id;
        });
        return this.state.ewalletPaymentInstruments.find((paymentInstrument) => {
            return paymentInstrument.Id === subcriberPaymentInstrumentIdObj.SubscriberPaymentInstrumentId;
        });
    }

    openPaymentMethodPopup(isEdit = false) {
        this.isEditingPaymentInstrument = isEdit;

        this.modalPaymentMethod = clone(isEdit ?
            this.getSubcriberPaymentInstrument() :
            BLANK_PAYMENT_INSTRUMENT
        );

        this.isModifyPaymentMethodPopupShown = true;
        this.$timeout(() => {
            this.modifyPaymentMethodPopupApi.open();
        });
    }

    openChangePaymentMethodPopup() {
        const offeringId = pathOr(null, ['PurchaseOrder', 'Items', 0, 'PurchaseInformation', 'OfferingId'], this.state.currentTransactionDetails);
        const changePopUpPaymentInstrument = this.state.convergentBillerSubscriberOfferingSummaries ? this.state.convergentBillerSubscriberOfferingSummaries.find((offerSummary) => {
            return offerSummary.OfferingId === offeringId;
        }): null;
        this.changePopUpPaymentInstrument = changePopUpPaymentInstrument.PaymentInstrument;
        this.actions.retrieveWallet({
            customerId: this.state.currentCustomerId
        }).then(() => {
            this.showChangePaymentPopup = true;
            this.$timeout(() => {
                this.changePaymentPopupApi.open();
            });
        });
    }

    onEmailSuccess() {
        this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.SUCCESSFUL_EMAIL), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.closeEmailDialog();
    }

    onBannerClose() {
        this.shouldShowBackBanner = false;
    }
}

export default {
    template: require('./detail.html'),
    controller: DetailController,
    controllerAs: 'TransactionDetail'
};
